
import {computed, defineComponent, onBeforeMount, onMounted, ref, unref as v, watchEffect} from 'vue'
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";

export default defineComponent({
  name: "XRayYearSelectDropdown",
  components: {MSimpleDropdownOption, MSimpleDropdownButton, MSimpleDropdown},
  emits: {
    onYear: (year: unknown): boolean => typeof year === "number"
  },
  props: {
    initialYear: {
      type: Number,
      required: false
    }
  },
  setup(props, {emit}) {
    const years = computed(() => {
      const currentYear = new Date().getFullYear()
      return Array.from({length: 30}, (_, i) => currentYear - i);
    })
    const selected = ref<number | null>(null)

    const isDropdown = ref(false)
    const dropdownID = 'x-ray-year-select-dd'
    const buttonText = computed(() => selected.value ? v(selected) : '')
    const title = computed(() => selected.value ? 'Valmistamise aasta' : 'Palun vali valmistamise aasta')
    const setIsDropdown = (newValue: boolean) => isDropdown.value = newValue

    onBeforeMount(() => selected.value = props.initialYear || null)
    onMounted(async () => {
      window.addEventListener("click", (e: MouseEvent) => {
        if (!isDropdown.value) return
        const target = e.target as HTMLDivElement
        if (target.closest(`#${dropdownID}`)) return
        setIsDropdown(false)
      })
    })

    watchEffect(() => {
      if (!selected.value) return
      emit('onYear', selected.value)
    })
    return {years, isDropdown, dropdownID, setIsDropdown, selected, title, buttonText}
  }
})
