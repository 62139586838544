
import {computed, defineComponent, PropType, ref} from 'vue'
import {RADIATION_STATUSES, X_RAY_EQUIPMENT_USAGES, XRayEquipmentDto} from "@/shared/types";
import BaseTableEditDeleteButtons from "@/components/utils/tables/BaseTableEditDeleteButtons.vue";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import EditClinicXRayEquipmentModal from "@/components/clinics/xrayequipments/EditClinicXRayEquipmentModal.vue";
import AreYouSureModal from "@/components/utils/modal/AreYouSureModal.vue";
import CopyButton from "@/components/utils/buttons/CopyButton.vue";
import {useImagesStore} from "@/store/images/ImagesStore";
import {useUserStore} from "@/store/user/UserStore";

export default defineComponent({
  name: "ClinicsXRayEquipmentsListItem",
  components: {CopyButton, AreYouSureModal, EditClinicXRayEquipmentModal, BaseTableEditDeleteButtons},
  props: {
    equipment: {
      type: Object as PropType<XRayEquipmentDto>,
      required: true
    }
  },
  setup(props) {
    const userStore = useUserStore()
    const userState = userStore.getState()

    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const cabinetsStore = useCabinetsStore()
    const imagesStore = useImagesStore()
    const cabinetsState = cabinetsStore.getState()
    const cabinet = computed(() => cabinetsState.cabinets.find(cabinet => cabinet.uid === props.equipment.cabinetUID))

    const today = new Date()

    const nextMaintenanceDate = computed(() => {
      if (!props.equipment.lastMaintenanceDate || !props.equipment.maintenanceIntervalsMonths) return
      const date = new Date(props.equipment.lastMaintenanceDate)
      date.setFullYear(date.getFullYear() + (props.equipment.maintenanceIntervalsMonths / 12))
      return date
    })

    const nextMaintenance = computed(() => nextMaintenanceDate.value ? nextMaintenanceDate.value.toLocaleDateString('en-GB') : undefined)

    const isNextMaintenanceClose = computed(() => {
      if (!nextMaintenanceDate.value) return false
      return new Date(today).setMonth(today.getMonth() + 1) >= nextMaintenanceDate.value.getTime()
    })

    const nextMeasurementDate = computed(() => {
      if (!props.equipment.lastMeasurementDate) return
      const date = new Date(props.equipment.lastMeasurementDate)
      date.setFullYear(date.getFullYear() + props.equipment.maintenanceIntervalYears)
      return date
    })

    const nextMeasurement = computed(() => nextMeasurementDate.value ? nextMeasurementDate.value.toLocaleDateString('en-GB') : undefined)

    const isNextMeasurementClose = computed(() => {
      if (!nextMeasurementDate.value) return false
      return new Date(today).setMonth(today.getMonth() + 1) >= nextMeasurementDate.value.getTime()
    })

    const isServiceDateClose = computed(() => isNextMaintenanceClose.value || isNextMeasurementClose.value)

    const deleteXRayEquipmentModal = ref(false)
    const deleteXRayEquipment = async () => {
      await xRayEquipmentsStore.deleteXRayEquipment(props.equipment.uid)
      await Promise.all([cabinetsStore.setCabinets(true), imagesStore.setImages(true)])
    }

    const editXRayEquipmentModal = ref(false)
    const onDeleteModalClose = async (action: boolean) => {
      if (action) await deleteXRayEquipment()
      deleteXRayEquipmentModal.value = false
    }


    return {
      X_RAY_EQUIPMENT_USAGES,
      RADIATION_STATUSES,
      nextMeasurement,
      cabinet,
      editXRayEquipmentModal,
      deleteXRayEquipmentModal,
      onDeleteModalClose,
      nextMaintenance,
      userState,
      isNextMaintenanceClose,
      isNextMeasurementClose,
      isServiceDateClose
    }
  }
})
