
import {computed, defineComponent, onBeforeMount, onMounted, PropType, ref, unref as v, watchEffect} from 'vue'
import {RADIATION_STATUSES, RadiationStatusType} from "@/shared/types";
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";

export default defineComponent({
  name: "XrayRadiationStatusDropdown",
  components: {MSimpleDropdownOption, MSimpleDropdownButton, MSimpleDropdown},
  emits: {
    onStatus: (option: unknown) => typeof option === "string" && Object.values(RadiationStatusType).includes(option as RadiationStatusType)
  },
  props: {
    initialRadiationStatus: {
      type: String as PropType<RadiationStatusType>,
      required: false
    }
  },
  setup(props, {emit}) {
    const options = Object.values(RadiationStatusType)
    const selected = ref<RadiationStatusType | null>(null)
    onBeforeMount(() => {
      if (props.initialRadiationStatus) selected.value = props.initialRadiationStatus
    })
    const selectedOption = computed(() => options.find(option => option === v(selected)))
    const isDropdown = ref(false)
    const dropdownId = 'x-ray-radiation-status-dd'
    const buttonText = computed(() => {
      const option = v(selectedOption)
      return option ? RADIATION_STATUSES[option] : ''
    })
    const title = computed(() => selectedOption.value ? 'Staatus' : 'Palun vali staatus')
    const setIsDropdown = (newValue: boolean) => isDropdown.value = newValue
    onMounted(() => {
      window.addEventListener("click", (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target.closest(`#${dropdownId}`)) setIsDropdown(false)
        }
      })
    })

    watchEffect(() => {
      if (selected.value) emit('onStatus', selected.value)
    })


    return {RADIATION_STATUSES, isDropdown, dropdownId, setIsDropdown, options, selected, title, buttonText}
  }


})
