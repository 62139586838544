
import { defineComponent, onMounted } from "vue";
import ClinicXRayEquipmentsList from "@/components/clinics/xrayequipments/ClinicXRayEquipmentsList.vue";
import { useXRayEquipmentsStore } from "@/store/xrayequipments/XRayEquipmentsStore";
import { useCabinetsStore } from "@/store/cabinets/CabinetsStore";

export default defineComponent({
  components: { ClinicXRayEquipmentsList },
  setup() {
    const xRayEquipmentsStore = useXRayEquipmentsStore();
    const cabinetsStore = useCabinetsStore();
    onMounted(async () => {
      await Promise.all([
        xRayEquipmentsStore.setXRayEquipments(false),
        cabinetsStore.setCabinets(false),
      ]);
    });
    return {};
  },
});
