
import { computed, defineComponent, onBeforeMount, PropType, reactive, ref, toRefs, } from "vue";
import EquipmentsDropdown from "@/components/clinics/utils/EquipmentsDropdown.vue";
import InputField from "@/components/utils/input/InputField.vue";
import { AddXRayEquipmentInput, AddXRayImageInput, EquipmentDto, } from "@/shared/types";
import { InputFieldType } from "@/types";
import CabinetsDropdown from "@/components/clinics/cabinets/utils/CabinetsDropdown.vue";
import XRayUsageDropdown from "@/components/clinics/xrayequipments/utils/XRayUsageDropdown.vue";
import XrayRadiationStatusDropdown from "@/components/clinics/xrayequipments/utils/XrayRadiationStatusDropdown.vue";
import XRayYearSelectDropdown from "@/components/xrayequipments/XRayYearSelectDropdown.vue";
import MDatePicker from "@/components/utils/input/mdatepicker/MDatePicker.vue";
import { useAppStore } from "@/store/app/AppStore";
import { TYPE } from "vue-toastification";
import XRayImagesAdd from "@/components/xrayequipments/XRayImagesAdd.vue";
import XRayMaintenanceIntervalDropdown from "@/components/xrayequipments/XRayMaintenanceIntervalDropdown.vue";
import { useUserStore } from "@/store/user/UserStore";

export default defineComponent({
  name: "ClinicXrayEquipmentForm",
  components: {
    XRayMaintenanceIntervalDropdown,
    XRayImagesAdd,
    MDatePicker,
    XRayYearSelectDropdown,
    XrayRadiationStatusDropdown,
    XRayUsageDropdown,
    CabinetsDropdown,
    InputField,
    EquipmentsDropdown,
  },
  emits: {
    onXrayEquipment: ({
      xRayEquipment,
      xRayImages,
    }: {
      xRayEquipment: unknown;
      xRayImages: unknown;
    }) => !!xRayEquipment && Array.isArray(xRayImages),
  },
  props: {
    xRayEquipment: {
      type: Object as PropType<AddXRayEquipmentInput>,
      required: false,
    },
    clinicUID: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {

    const userStore = useUserStore()
    const userState = userStore.getState()


    const isAdmin = computed(() => userState.admin)
    const appStore = useAppStore();
    const addXRayEquipmentInput = reactive<Partial<AddXRayEquipmentInput>>({
      cabinetUID: undefined,
      equipmentUID: undefined,
      name: undefined,
      maintenanceIntervalYears: undefined,
      maintenanceIntervalsMonths: undefined,
      lastMaintenanceDate: undefined,
      lastMeasurementDate: undefined,
      usageType: undefined,
      radiationStatusType: undefined,
      manufactureYear: undefined,
      model: undefined,
      serialNo: undefined,
      maximumPipeVoltage: undefined,
      maximumCurrent: undefined,
      manufacturer: undefined,
      manufacturerCountry: undefined,
      xRayTubeSerialNo: undefined,
      xRayTubeModel: undefined,
      imageUIDs: [],
    });

    onBeforeMount(() => {
      if (props.xRayEquipment) {
        addXRayEquipmentInput.cabinetUID = props.xRayEquipment.cabinetUID;
        addXRayEquipmentInput.equipmentUID = props.xRayEquipment.equipmentUID;
        addXRayEquipmentInput.name = props.xRayEquipment.name;
        addXRayEquipmentInput.maintenanceIntervalYears = props.xRayEquipment.maintenanceIntervalYears;
        addXRayEquipmentInput.maintenanceIntervalsMonths = props.xRayEquipment.maintenanceIntervalsMonths
        addXRayEquipmentInput.lastMaintenanceDate = props.xRayEquipment.lastMaintenanceDate;
        addXRayEquipmentInput.lastMeasurementDate = props.xRayEquipment.lastMeasurementDate;
        addXRayEquipmentInput.usageType = props.xRayEquipment.usageType;
        addXRayEquipmentInput.radiationStatusType = props.xRayEquipment.radiationStatusType;
        addXRayEquipmentInput.manufactureYear = props.xRayEquipment.manufactureYear;
        addXRayEquipmentInput.model = props.xRayEquipment.model;
        addXRayEquipmentInput.serialNo = props.xRayEquipment.serialNo;
        addXRayEquipmentInput.maximumPipeVoltage = props.xRayEquipment.maximumPipeVoltage;
        addXRayEquipmentInput.maximumCurrent = props.xRayEquipment.maximumCurrent;
        addXRayEquipmentInput.manufacturer = props.xRayEquipment.manufacturer;
        addXRayEquipmentInput.manufacturerCountry = props.xRayEquipment.manufacturerCountry;
        addXRayEquipmentInput.xRayTubeSerialNo = props.xRayEquipment.xRayTubeSerialNo;
        addXRayEquipmentInput.xRayTubeModel = props.xRayEquipment.xRayTubeModel;
        addXRayEquipmentInput.imageUIDs = props.xRayEquipment.imageUIDs;
      }
    });

    const onEquipment = ({
      name,
      uid,
      maintenanceIntervalYears,
    }: EquipmentDto) => {
      addXRayEquipmentInput.name = name;
      addXRayEquipmentInput.equipmentUID = uid;
      addXRayEquipmentInput.maintenanceIntervalYears = maintenanceIntervalYears;
    };
    const onLastMaintenanceDate = (date: Date) => (addXRayEquipmentInput.lastMaintenanceDate = date.getTime());
    const onLastMeasurementDate = (date: Date) => addXRayEquipmentInput.lastMeasurementDate = date.getTime()

    const xRayImages = ref<AddXRayImageInput[]>([]);
    const onImages = (images: AddXRayImageInput[]) =>
      (xRayImages.value = images);

    const checkAndGetNewXRayEquipment = ():
      | AddXRayEquipmentInput
      | undefined => {
      const {
        cabinetUID,
        equipmentUID,
        name,
        maintenanceIntervalYears,
        maintenanceIntervalsMonths,
        lastMaintenanceDate,
        lastMeasurementDate,
        usageType,
        radiationStatusType,
        manufactureYear,
        model,
        serialNo,
        maximumPipeVoltage,
        maximumCurrent,
        manufacturer,
        manufacturerCountry,
        xRayTubeSerialNo,
        xRayTubeModel
      } = toRefs(addXRayEquipmentInput);

      if (!cabinetUID?.value) appStore.showToast(TYPE.WARNING, "Palun valige kabinet");
      else if (!equipmentUID?.value) appStore.showToast(TYPE.WARNING, "Palun valige seade");
      else if (!name?.value) appStore.showToast(TYPE.WARNING, "Palun valige seade");
      else if (!maintenanceIntervalYears?.value) appStore.showToast(TYPE.WARNING, "Palun valige seade");
      else if (!maintenanceIntervalsMonths?.value) appStore.showToast(TYPE.WARNING, 'Malun valige hooldusvälp')
      else if (!lastMaintenanceDate?.value) appStore.showToast(TYPE.WARNING, "Palun valige viimase hoolduse kuupäev");
      else if (!lastMeasurementDate?.value) appStore.showToast(TYPE.WARNING, "Palun valige viimase mõõdistamise kuupäev");
      else if (!usageType?.value) appStore.showToast(TYPE.WARNING, "Palun valige kasutus");
      else if (!radiationStatusType?.value) appStore.showToast(TYPE.WARNING, "Palun valige kiirgustegevuse staatus");
      else if (!manufactureYear?.value) appStore.showToast(TYPE.WARNING, "Palun valige valmistamise aasta");
      else if (!model?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage seadme mudel");
      else if (!serialNo?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage seadme seerianumber");
      else if (!maximumPipeVoltage?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage maksimum torupinge");
      else if (!maximumCurrent?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage maksimum voolutugevus");
      else if (!manufacturer?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage tootja");
      else if (!manufacturerCountry?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage tootjariik");
      else if (!xRayImages.value.length && !props.xRayEquipment) appStore.showToast(TYPE.WARNING, "Palun lisage ülesvõtted");
      else if (!xRayTubeSerialNo?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage röntgentoru seerianumber");
      else if (!xRayTubeModel?.value) appStore.showToast(TYPE.WARNING, "Palun sisestage röntgentoru mudel");
      else {
        return {
          cabinetUID: cabinetUID.value,
          equipmentUID: equipmentUID.value,
          name: name.value,
          maintenanceIntervalYears: maintenanceIntervalYears.value,
          maintenanceIntervalsMonths: maintenanceIntervalsMonths.value,
          lastMaintenanceDate: lastMaintenanceDate.value,
          lastMeasurementDate: lastMeasurementDate.value,
          usageType: usageType.value,
          radiationStatusType: radiationStatusType.value,
          manufactureYear: manufactureYear.value,
          model: model.value,
          serialNo: serialNo.value,
          maximumPipeVoltage: maximumPipeVoltage.value,
          maximumCurrent: maximumCurrent.value,
          manufacturer: manufacturer.value,
          manufacturerCountry: manufacturerCountry.value,
          xRayTubeSerialNo: xRayTubeSerialNo.value,
          xRayTubeModel: xRayTubeModel.value,
          imageUIDs: [],
        };
      }

      return undefined;
    };

    const onEquipmentAdd = async () => {
      const input = checkAndGetNewXRayEquipment();
      if (input) {
        emit("onXrayEquipment", {
          xRayEquipment: input,
          xRayImages: xRayImages.value,
        });
      }
    };

    return {
      isAdmin,
      onEquipmentAdd,
      onImages,
      InputFieldType,
      ...toRefs(addXRayEquipmentInput),
      onEquipment,
      onLastMaintenanceDate,
      onLastMeasurementDate
    };
  },
});
