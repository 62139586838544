
import { defineComponent } from "vue";
import { useUserStore } from "@/store/user/UserStore";

export default defineComponent({
  name: "ClinicsXRayEquipmentsListHeader",
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    return { userState };
  },
});
