
import {computed, defineComponent, PropType, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import ClinicXrayEquipmentForm from "@/components/forms/ClinicXrayEquipmentForm.vue";
import {AddXRayEquipmentInput, AddXRayImageInput, XRayEquipmentDto} from "@/shared/types";
import {useXRayEquipmentsStore} from '@/store/xrayequipments/XRayEquipmentsStore';

export default defineComponent({
  name: "EditClinicXRayEquipmentModal",
  components: {ClinicXrayEquipmentForm, ModalWrapper},
  emits: ['onClose'],
  props: {
    xRayEquipment: {
      type: Object as PropType<XRayEquipmentDto>,
      required: true
    }
  },
  setup(props, {emit}) {
    const isOpen = ref(true)
    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const xRayEquipmentInput = computed<AddXRayEquipmentInput>(() => ({
      equipmentUID: props.xRayEquipment.equipmentUID,
      name: props.xRayEquipment.name,
      maintenanceIntervalYears: props.xRayEquipment.maintenanceIntervalYears,
      maintenanceIntervalsMonths: props.xRayEquipment.maintenanceIntervalsMonths,
      lastMaintenanceDate: props.xRayEquipment.lastMaintenanceDate,
      lastMeasurementDate: props.xRayEquipment.lastMeasurementDate,
      model: props.xRayEquipment.model,
      serialNo: props.xRayEquipment.serialNo,
      maximumPipeVoltage: props.xRayEquipment.maximumPipeVoltage,
      maximumCurrent: props.xRayEquipment.maximumCurrent,
      manufacturer: props.xRayEquipment.manufacturer,
      manufacturerCountry: props.xRayEquipment.manufacturerCountry,
      xRayTubeSerialNo: props.xRayEquipment.xRayTubeSerialNo,
      xRayTubeModel: props.xRayEquipment.xRayTubeModel,
      cabinetUID: props.xRayEquipment.cabinetUID,
      usageType: props.xRayEquipment.usageType,
      radiationStatusType: props.xRayEquipment.radiationStatusType,
      manufactureYear: props.xRayEquipment.manufactureYear,
      imageUIDs: props.xRayEquipment.imageUIDs,
    }))

    const onXrayEquipmentEdit = async ({
                                         xRayEquipment,

                                       }: { xRayEquipment: AddXRayEquipmentInput, xRayImages: AddXRayImageInput[] }) => {
      await xRayEquipmentsStore.editXRayEquipment({uid: props.xRayEquipment.uid, ...xRayEquipment})
      emit('onClose')

    }
    return {onXrayEquipmentEdit, xRayEquipmentInput, isOpen}
  }
})
