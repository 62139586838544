
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watchEffect,
} from "vue";
import { AddXRayImageInput } from "@/shared/types";
import InputField from "@/components/utils/input/InputField.vue";
import { InputFieldType } from "@/types";
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import XRayImagesDropdown from "@/components/xrayequipments/XRayImagesDropdown.vue";

export default defineComponent({
  name: "XRayImagesAdd",
  components: { XRayImagesDropdown, ButtonAdd, InputField },
  emits: {
    onImages: (images: unknown) => Array.isArray(images),
  },
  setup(_, { emit }) {
    const currentImageName = ref<AddXRayImageInput["imageName"]>("");
    const currentImageSecondsRate = ref<AddXRayImageInput["secondsRate"]>(0);
    const currentImageDoseRate = ref<AddXRayImageInput["doseRate"]>(0);
    const images = ref<AddXRayImageInput[]>([]);
    const resetFields = () => {
      currentImageName.value = "";
      currentImageSecondsRate.value = 0;
      currentImageDoseRate.value = 0;
    };
    onBeforeMount(() => resetFields());
    const isCurrentImageValid = computed(() => {
      return (
        !!currentImageName.value &&
        !!currentImageSecondsRate.value &&
        !!currentImageDoseRate.value
      );
    });
    const onAddImage = () => {
      if (!isCurrentImageValid.value) return;
      images.value.push({
        imageName: currentImageName.value,
        secondsRate: currentImageSecondsRate.value,
        doseRate: currentImageDoseRate.value,
      });
      emit("onImages", images.value);
      resetFields();
    };

    const removeImage = (imageIndex: number) => {
      images.value.splice(imageIndex, 1);
      emit("onImages", images.value);
    };

    return {
      images,
      currentImageName,
      currentImageSecondsRate,
      currentImageDoseRate,
      InputFieldType,
      onAddImage,
      isCurrentImageValid,
      removeImage,
    };
  },
});
