import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "font-normal truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MSimpleDropdownButton = _resolveComponent("MSimpleDropdownButton")!
  const _component_MSimpleDropdownOption = _resolveComponent("MSimpleDropdownOption")!
  const _component_MSimpleDropdown = _resolveComponent("MSimpleDropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MSimpleDropdown, {
      open: _ctx.isDropdown,
      id: _ctx.dropdownId
    }, {
      trigger: _withCtx(() => [
        _createVNode(_component_MSimpleDropdownButton, {
          open: _ctx.isDropdown,
          "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDropdown) = $event)),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIsDropdown(!_ctx.isDropdown))),
          disabled: false,
          "is-selected": !!_ctx.selected,
          title: _ctx.title,
          loading: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
          ]),
          _: 1
        }, 8, ["open", "is-selected", "title"])
      ]),
      options: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createBlock(_component_MSimpleDropdownOption, {
            key: option,
            active: _ctx.selected === option,
            onClick: ($event: any) => {;(_ctx.selected = option), _ctx.setIsDropdown(!_ctx.isDropdown)}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.RADIATION_STATUSES[option]), 1)
              ])
            ]),
            _: 2
          }, 1032, ["active", "onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["open", "id"])
  ]))
}