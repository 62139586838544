
import {computed, defineComponent, onBeforeMount, onMounted, ref, watchEffect} from 'vue'
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";

export default defineComponent({
  name: "XRayMaintenanceIntervalDropdown",
  components: {MSimpleDropdownOption, MSimpleDropdownButton, MSimpleDropdown},
  emits: {
    onInterval: (interval: unknown) => typeof interval === 'number'
  },
  props: {
    initial: {
      type: Number,
      required: false
    }
  },
  setup(props, {emit}) {
    const intervals = [12, 24]
    const selected = ref<number | null>(null)
    const isDropdown = ref(false)
    const dropdownID = 'x-ray-maintenance-interval-dd'
    const buttonText = computed(() => selected.value ? selected.value : '')
    const title = computed(() => selected.value ? 'Hooldusvälp (kuud)' : 'Palun vali hooldusvälp (kuud)')
    const setIsDropdown = (status: boolean) => isDropdown.value = status

    onBeforeMount(() => selected.value = props.initial || null)
    onMounted(() => {
      window.addEventListener('click', (e: MouseEvent) => {
        if (!isDropdown.value) return
        const target = e.target as HTMLDivElement
        if (target.closest(`#${dropdownID}`)) return
        setIsDropdown(false)
      })
    })

    watchEffect(() => {
      if (!selected.value) return
      emit('onInterval', selected.value)
    })
    return {intervals, isDropdown, dropdownID, setIsDropdown, selected, title, buttonText}
  }
})
