
import {computed, defineComponent, onMounted, ref, unref as v, watchEffect} from 'vue'
import MSimpleDropdown from "@/components/utils/input/dropdowns/MSimpleDropdown.vue";
import MSimpleDropdownButton from "@/components/utils/input/dropdowns/MSimpleDropdownButton.vue";
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";
import {useEquipmentsStore} from "@/store/equipments/EquipmentsStore";
import {EquipmentDto} from "@/shared/types";

export default defineComponent({
  name: "EquipmentsDropdown",
  components: {
    MSimpleDropdownOption,
    MSimpleDropdown,
    MSimpleDropdownButton
  },
  emits: {
    onEquipment: (equipment: Partial<EquipmentDto> | undefined) => {
      return equipment && typeof equipment === "object" && "name" in equipment && "maintenanceIntervalYears" in equipment && "uid" in equipment;
    }
  },
  setup(_, {emit}) {
    const equipmentsStore = useEquipmentsStore()
    const equipmentsState = equipmentsStore.getState()

    const equipments = computed(() => equipmentsState.equipments)
    const selected = ref<string | null>(null)
    const selectedEquipment = computed(() => v(equipments).find(({uid}) => uid === v(selected)))
    const isDropdown = ref(false)
    const dropDownId = 'equipments-dd'
    const buttonText = computed(() => selectedEquipment.value?.name || '')
    const title = computed(() => selectedEquipment.value ? 'Seade' : 'Palun vali seade')
    const setIsDrowdown = (newValue: boolean) => isDropdown.value = newValue
    onMounted(async () => {
      await equipmentsStore.setEquipments(false)
      window.addEventListener('click', (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target?.closest(`#${dropDownId}`)) setIsDrowdown(false)
        }
      })
    })
    watchEffect(() => {
      if (selected.value) emit('onEquipment', v(selectedEquipment))
    })
    return {equipments, isDropdown, dropDownId, setIsDrowdown, selected, title, buttonText}
  }
})
