
import {computed, defineComponent} from 'vue'
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import BaseTableBodyWrapper from "@/components/utils/tables/BaseTableBodyWrapper.vue";
import ClinicsXRayEquipmentsListItem from "@/components/clinics/xrayequipments/ClinicsXRayEquipmentsListItem.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";

export default defineComponent({
  name: "ClinicsXRayEquipmentsListBody",
  components: {ClinicsXRayEquipmentsListItem, BaseTableBodyWrapper},
  props: {
    nameFilterInput: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)

    const cabinetsStore = useCabinetsStore()
    const cabinetsState = cabinetsStore.getState()
    const allCabinets = computed(() => cabinetsState.cabinets)
    const activeCabinets = computed(() => activeClinicUID.value ? allCabinets.value.filter(cabinet => cabinet.clinicUID === activeClinicUID.value) : allCabinets.value)
    const activeCabinetsUIDs = computed(() => activeCabinets.value.map(cabinet => cabinet.uid))
    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const xRayEquipmentsState = xRayEquipmentsStore.getState()
    const allEquipments = computed(() => xRayEquipmentsState.xRayEquipments)
    const equipments = computed(() => activeClinicUID.value ? allEquipments.value.filter(equipment => activeCabinetsUIDs.value.includes(equipment.cabinetUID)) : allEquipments.value)
    const filteredEquipments = computed(
        () => props.nameFilterInput?.length
            ? equipments.value.filter(e => e.name.toLowerCase().includes((props.nameFilterInput || '').toLowerCase()))
            : equipments.value
    )
    return {filteredEquipments}
  }
})
