
import { defineComponent, ref } from "vue";
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import AddClinicXRayEquipmentModal from "@/components/clinics/xrayequipments/addxrayequipment/AddClinicXRayEquipmentModal.vue";

export default defineComponent({
  name: "AddClinicXRayEquipment",
  components: { AddClinicXRayEquipmentModal, ButtonAdd },
  setup() {
    const addEquipmentModal = ref(false);
    return { addEquipmentModal };
  },
});
