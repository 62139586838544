import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MSimpleDropdownButton = _resolveComponent("MSimpleDropdownButton")!
  const _component_MSimpleDropdownOption = _resolveComponent("MSimpleDropdownOption")!
  const _component_MSimpleDropdown = _resolveComponent("MSimpleDropdown")!

  return (_openBlock(), _createBlock(_component_MSimpleDropdown, {
    open: _ctx.isDropdown,
    id: _ctx.dropdownID
  }, {
    trigger: _withCtx(() => [
      _createVNode(_component_MSimpleDropdownButton, {
        open: _ctx.isDropdown,
        "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDropdown) = $event)),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setIsDropdown(!_ctx.isDropdown))),
        disabled: false,
        "is-selected": !!_ctx.selected,
        title: _ctx.title,
        loading: false
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["open", "is-selected", "title"])
    ]),
    options: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intervals, (interval) => {
        return (_openBlock(), _createBlock(_component_MSimpleDropdownOption, {
          key: interval,
          active: interval === _ctx.selected,
          onClick: ($event: any) => {;(_ctx.selected = interval), _ctx.setIsDropdown(!_ctx.isDropdown)}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(interval), 1)
          ]),
          _: 2
        }, 1032, ["active", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["open", "id"]))
}