import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_6 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_7 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_8 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_9 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_10 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_11 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_12 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_13 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_14 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_15 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_16 = { class: "px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700" }
const _hoisted_17 = { class: "px-6 py-4 whitespace-nowrap text-right text-sm font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyButton = _resolveComponent("CopyButton")!
  const _component_BaseTableEditDeleteButtons = _resolveComponent("BaseTableEditDeleteButtons")!
  const _component_EditClinicXRayEquipmentModal = _resolveComponent("EditClinicXRayEquipmentModal")!
  const _component_AreYouSureModal = _resolveComponent("AreYouSureModal")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(_ctx.isServiceDateClose ? 'bg-red-50' : '')
  }, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.equipment.name), 1),
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_CopyButton, {
        content: _ctx.equipment.uid
      }, null, 8, ["content"])
    ]),
    _createElementVNode("td", {
      class: _normalizeClass(["px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700",  _ctx.isNextMaintenanceClose ? 'bg-red-200' : ''])
    }, _toDisplayString(_ctx.nextMaintenance), 3),
    _createElementVNode("td", {
      class: _normalizeClass(["px-6 py-4 whitespace-nowrap text-sm font-normal text-gray-700",  _ctx.isNextMeasurementClose ? 'bg-red-200' : ''])
    }, _toDisplayString(_ctx.nextMeasurement), 3),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.cabinet?.name), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.equipment.model), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.equipment.serialNo), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.equipment.xRayTubeModel), 1),
    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.equipment.xRayTubeSerialNo), 1),
    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.equipment.maximumPipeVoltage), 1),
    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.equipment.maximumCurrent), 1),
    _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.X_RAY_EQUIPMENT_USAGES[_ctx.equipment.usageType]), 1),
    _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.equipment.manufacturer), 1),
    _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.equipment.manufacturerCountry), 1),
    _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.equipment.manufactureYear), 1),
    _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.RADIATION_STATUSES[_ctx.equipment.radiationStatusType]), 1),
    _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.equipment.maintenanceIntervalsMonths) + " " + _toDisplayString(_ctx.equipment.maintenanceIntervalsMonths ? 'kuud' : ''), 1),
    _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.equipment.maintenanceIntervalYears) + " aastat ", 1),
    _createElementVNode("td", _hoisted_17, [
      _createVNode(_component_BaseTableEditDeleteButtons, {
        "show-delete": _ctx.userState.admin,
        onOnEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editXRayEquipmentModal = true)),
        onOnDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteXRayEquipmentModal = true))
      }, null, 8, ["show-delete"]),
      (_ctx.editXRayEquipmentModal)
        ? (_openBlock(), _createBlock(_component_EditClinicXRayEquipmentModal, {
            key: 0,
            onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editXRayEquipmentModal = false)),
            "x-ray-equipment": _ctx.equipment
          }, null, 8, ["x-ray-equipment"]))
        : _createCommentVNode("", true),
      (_ctx.deleteXRayEquipmentModal)
        ? (_openBlock(), _createBlock(_component_AreYouSureModal, {
            key: 1,
            onOnAction: _ctx.onDeleteModalClose
          }, null, 8, ["onOnAction"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}