
import {computed, defineComponent, ref} from 'vue'
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import ClinicXrayEquipmentForm from "@/components/forms/ClinicXrayEquipmentForm.vue";
import {AddXRayEquipmentInput, AddXRayImageInput} from "@/shared/types";
import {addXRayImagesFun} from "@/firebase/functions";
import {useXRayEquipmentsStore} from "@/store/xrayequipments/XRayEquipmentsStore";
import {useCabinetsStore} from "@/store/cabinets/CabinetsStore";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useImagesStore} from "@/store/images/ImagesStore";


export default defineComponent({
  name: "AddClinicXRayEquipmentModal",
  components: {ClinicXrayEquipmentForm, ModalWrapper},
  emits: {
    onClose: () => true
  },
  setup(_, {emit}) {
    const isOpen = ref(true)
    const xRayEquipmentsStore = useXRayEquipmentsStore()
    const cabinetsStore = useCabinetsStore()
    const clinicsStore = useClinicsStore()
    const imagesStore = useImagesStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)
    const onXrayEquipment = async ({
                                     xRayEquipment,
                                     xRayImages
                                   }: { xRayEquipment: AddXRayEquipmentInput, xRayImages: AddXRayImageInput[] }) => {
      const {data: imageIDs} = await addXRayImagesFun(xRayImages)
      xRayEquipment.imageUIDs = imageIDs
      await xRayEquipmentsStore.addXRayEquipment(xRayEquipment)
      await Promise.all([cabinetsStore.setCabinets(true), imagesStore.setImages(true)])
      emit('onClose')
    }
    return {isOpen, onXrayEquipment, activeClinicUID}
  }
})
