
import { defineComponent, ref } from "vue";
import AddClinicXRayEquipment from "@/components/clinics/xrayequipments/addxrayequipment/AddClinicXRayEquipment.vue";
import ClinicsXRayEquipmentsListHeader from "@/components/clinics/xrayequipments/ClinicsXRayEquipmentsListHeader.vue";
import ClinicsXRayEquipmentsListBody from "@/components/clinics/xrayequipments/ClinicsXRayEquipmentsListBody.vue";
import BaseTableWrapper from "@/components/utils/tables/BaseTableWrapper.vue";
import InputField from "@/components/utils/input/InputField.vue";
import { useUserStore } from "@/store/user/UserStore";

export default defineComponent({
  name: "ClinicXRayEquipmentsList",
  components: {
    InputField,
    BaseTableWrapper,
    ClinicsXRayEquipmentsListBody,
    ClinicsXRayEquipmentsListHeader,
    AddClinicXRayEquipment,
  },
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const nameSearchInput = ref<string | undefined>(undefined);
    return { nameSearchInput, userState };
  },
});
